import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../layouts"
import SEO from "../components/seo"
import Heading from "../components/Heading"
// import SectionLink from "../components/SectionLink"

const BespokeText = styled.p`
  max-width: 1000px;
  text-align: center;
  margin: 100px 0;
  padding: 0 10px;
  font-size: 1.5rem;
  line-height: 2.5rem;
`

// const StyledLink = styled(Link)`
//   border-radius: 10px;
//   margin: 0 auto 100px;
//   background: #ff5400;
//   padding: 15px;
//   font-size: calc(1vw + 1rem);
//   color: #e7dfd4;
//   font-weight: 300;
//   transition: 0.3s ease all;
//   border: 2px #ff5400 solid;
//   box-sizing: border-box;
//   display: inline;
//   &:hover {
//     background: none;
//     color: #ff5400;
//   }
// `

const SecondPage = () => (
  <Layout>
    <SEO title="About" />
    <Heading>Bespoke</Heading>
    <BespokeText>
      There’s a garment waiting to express your unique personality, and I’d love
      to work with you to make it a reality.
      <br />
      <br />
      Are you ready to step into your own version of an iconic Nudie suit? Do
      you have a favourite old jacket that is begging to have your personal
      stamp emblazoned on it in thread? A custom bridal jacket for your big day?
      Want to turn an ordinary garment into a treasured heirloom or surprise
      someone with a gift that can’t be replicated?
      <br />
      <br />
      <Link to="/contact">Get in touch</Link> and let’s dream up the perfect
      idea together!
      <br />
      <br />
      From something as simple as stitching a name on a denim trucker to full
      tailored suits, made to your measurements and fit to perfection, if you
      have a vision, I can make it happen!
    </BespokeText>
  </Layout>
)

export default SecondPage

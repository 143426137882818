/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Header from "../components/header"
import Footer from "../components/Footer"
import { device } from "../../lib/Devices"
import "../components/layout.css"
// import "../components/snipcart.css"

const MainPage = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fffbf4;

  main {
    padding: 0 15px;
    margin: 100px auto 0;
    max-width: 1600px;
    flex: 1 0 auto;
    @media ${device.desktop} {
      padding: 0;
    }
  }
  footer {
    flex-shrink: 0;
  }
`

const Layout = ({ children }) => {
  return (
    <MainPage>
      <Header />
      <main>{children}</main>
      <Footer />
    </MainPage>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from "react"
import styled from "styled-components"
import { device } from "../../lib/Devices"

const StyledH1 = styled.h1`
  margin: 0 auto 0;
  text-align: center;
  font-style: italic;
  font-weight: 300;
  font-size: 2rem;
  @media ${device.tablet} {
    font-size: 2.5rem;
  }
`

const Heading = ({ children }) => {
  return <StyledH1>{children}</StyledH1>
}

export default Heading
